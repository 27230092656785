<template>
	<div class="page-thumbnail-panel" :style="'background-image: url('+pageData.cover+')'" v-loading="loading" v-if="pageData">
		<span class="unpublish" v-if="!pageData.published_at && showPublishState">未发布</span>
		<div class="header-mask">
			<div class="details-btn" @click="preview(pageData.id)">预览</div>
		</div>
		<div class="page-item-foot">
			<div class="item-name">{{pageData.name}}</div>
			<div class="item-title">{{pageData.title}}</div>
			<div class="bottom" v-if="btnList.length">
				<el-button type="text" size="mini" v-if="btnList.includes('edit')" @click="onHandle('edit')">编辑</el-button>
				<el-button type="text" size="mini" v-if="btnList.includes('use_template')" @click="copyPage">使用模板</el-button>
				<el-button type="text" size="mini" v-if="btnList.includes('copy_template')" @click="onHandle('copy_page')">复制</el-button>
				<el-dropdown @command="onHandle" placement="top-end" v-if="showMoreBtn">
					<el-button type="text" size="mini">操作</el-button>
					<el-dropdown-menu>
						<template v-for="(item, index) in operationDataList">
							<el-dropdown-item :key="index" :command="item.eventType" v-if="btnList.includes(item.eventType)">
								<font :class="item.extraClassName">{{item.title}}</font>
							</el-dropdown-item>
						</template>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
	<div class="page-thumbnail-panel create" v-loading="loading" v-else>
		<div class="temp-create" @click="newPage">
			<i class="el-icon-plus" style="font-size: 28px; font-weight: bold"></i>
			<p class="paddingT10">新建作品</p>
		</div>
	</div>
</template>

<script>
	import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
	import editorProjectConfig from '@/utils/h5-model';

	import addCooperationer from '@/components/add-cooperationer/index.js'
	export default {
		props: {
			showPublishState: {
				type: Boolean,
				default: true
			},
			showMoreBtn: {
				type: Boolean,
				default: true
			},
			pageType: {
				type: String,
				default: 'h5'
			},
			pageData: Object,
			// 操作按钮显示哪些按钮  根据type来匹配。
			btnList: {
				type: Array,
				default: () => {
					return []
				}
			},
		},
		components: {
			[Dropdown.name]: Dropdown,
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem
		},
		computed: {
			
		},
		data() {
			return {
				loading: false,
				operationDataList: [{
					title: '发布作品',
					eventType: 'publish',
					extraClassName: '',
				}, {
					title: '发布市场',
					eventType: 'publish_template',
					extraClassName: '',
				}, {
					title: '复制链接',
					eventType: 'copy_url',
				}, {
					title: '设为模板',
					eventType: 'set_template',
				}, {
					title: '作品数据',
					eventType: 'view_data',
				}, {
					title: '协作设置',
					eventType: 'cooperation',
				}, {
					title: '删除作品',
					eventType: 'delete',
					extraClassName: 'error',
				}, {
					title: '退出协作',
					eventType: 'un_cooperation',
					extraClassName: 'error',
				}]
			}
		},
		methods: {
			onHandle (t) {
				switch (t) {
					case 'publish':
						this.$confirm('确认发布《'+this.pageData.name+'》作品？', '操作提示', {
							confirmButtonText: '发布',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(async () => {
							this.loading = true;
							const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'publish', id: this.pageData.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							this.loading = false;
							if (code != 0) return this.$message.error(msg);
							this.$message.success(msg);
							this.preview(this.pageData.id);
							this.$emit('refresh');
						});
						break;
					case 'edit':
						this.$router.push({name: 'H5Create', query: {id: this.pageData.id}});
						break;
					case 'copy_url':
						this.$message.success('即将开放。。。');
						break;
					case 'copy_page':
						this.$confirm('确认复制《'+this.pageData.name+'》作品？', '操作提示', {
							confirmButtonText: '复制',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(async () => {
							this.loading = true;
							const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'copy_page', id: this.pageData.id}, headers: {loading: true}});
							const { code, msg, result } = res.data;
							this.loading = false;
							if (code != 0) return this.$message.error(msg);
							this.$router.push({name: 'H5Create', query: {id: result.id}});
						});
						break;
					case 'set_template':
						this.$confirm('确认添加《'+this.pageData.name+'》到我的模板?', '操作提示', {
							confirmButtonText: '发布',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(async () => {
							this.loading = true;
							const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'set_template', id: this.pageData.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							this.loading = false;
							if (code != 0) return this.$message.error(msg);
							this.$message.success(msg);
						});
						break;
					case 'view_data':
						this.$router.push({name: 'pageDataDetail', query: {id: this.pageData.id}})
						break;
					case 'cooperation':
						addCooperationer(this.pageData.id)
						break;
					case 'delete':
						this.$confirm('确认删除《'+this.pageData.name+'》作品？删除后，将无法访问此作品?', '操作提示', {
							confirmButtonText: '删除',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(async () => {
							const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'delete', id: this.pageData.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.$message.success(msg);
							this.$emit('refresh');
						});
						break;
					case 'un_cooperation':
						this.$confirm('确认退出协作《'+this.pageData.name+'》编辑？退出后，将无法编辑此作品?', '操作提示', {
							confirmButtonText: '编辑',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(async () => {
							const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'un_cooperation', id: this.pageData.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.$message.success(msg);
							this.$emit('refresh');
						});
						break;
					case 'publish_template':
						this.$confirm('确认发布《'+this.pageData.name+'》到模板市场?', '操作提示', {
							confirmButtonText: '发布',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(async () => {
							this.loading = true;
							const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'publish_template', id: this.pageData.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							this.loading = false;
							if (code != 0) return this.$message.error(msg);
							this.$message.success(msg);
						});
						break;
				}
			},
			async newPage () {
				this.loading = true;
				const page = editorProjectConfig.getProjectConfig();
				const res = await this.$http.post(this.$api.URI_H5PAGES, {...page});
				const { code, msg, result } = res.data;
				this.loading = false;
				if (code != 0) return this.$message.error(msg);
				this.$router.push({name: 'H5Create', query: {id: result.id}});
			},
			preview (id) {
				this.$emit('showPreview', id)
			}
		}
	}
</script>

<style scoped>
	.page-thumbnail-panel {
		width: 224px;
		height: 296px;
		display: flex;
		overflow: hidden;
		position: relative;
		border-radius: 4px;
		background-color: white;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		flex-direction: column;
		transition: all 0.28s;
		box-shadow: 0 0 5px 1px #ccc;
	}
	.page-thumbnail-panel:hover {
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
		transform: translate3d(0, -2px, 0);
	}
	.page-thumbnail-panel:hover .header-mask {
		opacity: 1;
	}
	.page-thumbnail-panel .header-mask {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		background-color: rgba(0, 0, 0, .3);
		width: 100%;
		height: 100%;
		border-radius: 4px 4px 0 0;
		padding-top: 92px;
		text-align: center;
		transition: top .28s ease, opacity .28s ease, height .28s ease;
	}
	.page-thumbnail-panel .header-mask .details-btn {
		display: inline-block;
		width: 120px;
		height: 44px;
		font-size: 18px;
		line-height: 44px;
		border-radius: 22px;
		border: 1px solid #fff;
		color: #fff;
		cursor: pointer;
	}
	.page-item-foot {
		font-size: 14px;
		line-height: 1.5;
		padding: 8px 8px 0;
		background-color: rgba(255, 255, 255, 1);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.page-item-foot .item-title {
		color: #999;
		font-size: 12px;
	}
	.page-item-foot .bottom {
		display: flex;
		justify-content: space-between;
	}
	.unpublish {
		color: #fff;
		height: 30px;
		display: block;
		padding: 0 10px;
		font-size: 12px;
		line-height: 30px;
		background-color: #76838f;
		position: absolute;
		border-top-left-radius: 4px;
		border-bottom-right-radius: 12px;
		z-index: 10;
	}
	.page-thumbnail-panel.create {
		padding: 16px;
		text-align: center;
	}
	.page-thumbnail-panel.create .temp-create {
		display: inline-block;
		width: 100%;
		height: 100%;
		border: 1px solid #e6ebed;
		border-radius: 3px;
		padding-top: 100px;
		transition: all 0.28s;
		cursor: pointer;
	}
	.page-thumbnail-panel.create .temp-create &:hover {
		color: $primary;
		border-color: $primary;
	}
</style>

