<template>
	<div class="clearfix my-page-list" :style="'height: '+(theme.height-160)+'px'">
		<el-scrollbar class="scroll-wrapper page-list-wrapper">
			<div class="page-content">
				<div class="my-page-nav-list">
					<div class="my-page-nav-item" @click="doSearch('my')" :class="{active: params.type === 'my'}">我的创作({{my_count}})</div>
					<div class="my-page-nav-item" @click="doSearch('cooperation')" :class="{active: params.type === 'cooperation'}">参与创作({{share_count}})</div>
					<div class="my-page-nav-item" @click="doSearch('market')" :class="{active: params.type === 'market'}">模板市场</div>
					<div class="my-page-nav-item" @click="doSearch('recycle')" :class="{active: params.type === 'recycle'}">回收站</div>
				</div>
				<!--页面列表-->
				<div class="page-item-wrapper" v-loading="loading">
					<div class="page-item">
						<thumbnailPanel :pageType="params.render" />
					</div>
					<div class="page-item" v-for="(item, index) in pages" :key="index">
						<thumbnailPanel :pageData="item" :btnList="operationBtn(item.published_at)" @refresh="getH5Pages" @showPreview="showPreviewFn"/>
					</div>
				</div>
			</div>
		</el-scrollbar>
		<!--预览-->
		<previewPage :showDrawer="show_preview" :id="previewId" @closePreview="show_preview = false"></previewPage>
	</div>
</template>

<script>
	import thumbnailPanel from '@/components/thumbnail-panel'
	import previewPage from '../editor/components/preview'
	import {mapState} from 'vuex'

	export default {
		components: {
			thumbnailPanel,
			previewPage
		},
		data() {
			return {
				loading: false,
				pages: [],
				my_count: 0,
				share_count: 0,
				params: {
					type: 'my',
					render: 'h5'
				},
				previewId: '',
				show_preview: false,
			}
		},
		computed: {
			...mapState({
				theme: state => state.theme
			})
		},
		created() {
			this.getH5Pages(this.params);
		},
		methods: {
			// 操作按钮类型，分为我的，我的协作，和已发布的三种状态
			operationBtn(isPublished) {
				let baseBtn = ['edit', 'copy_template', 'set_template'];
				if (this.params.type === 'my') {
					baseBtn = [...baseBtn, 'cooperation', 'delete']
				}
				if (this.params.type === 'cooperation') {
					baseBtn = [...baseBtn, 'un_cooperation']
				}
				if (isPublished) {
					baseBtn = [...baseBtn, 'copy_url', 'view_data']
				} else {
					baseBtn = [...baseBtn, 'publish']
				}
				return baseBtn;
			},
			/**
			 * 搜索我的页面，type: my时搜索我的作品， type: share搜索我参与的作品
			 */
			doSearch(type) {
				this.params.type = type;
				this.getH5Pages(this.params)
			},
			/**
			 * 切换页面类型
			 * */
			handlePageModeClick(val) {
				this.params.render = val.name;
				this.getH5Pages(this.params)
			},
			async getH5Pages (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_H5PAGES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.pages = result.data;
				this.my_count = result.my_count;
				this.share_count = result.my_cooperation_count;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			showPreviewFn(id) {
				this.previewId = id;
				this.show_preview = true;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.my-page-list {
		display: flex;
	}

	.page-list-wrapper {
		flex: 1;
	}

	.my-page-nav-list {
		height: 40px;
		line-height: 40px;
		z-index: 2;
		margin-bottom: 20px;
		.my-page-nav-item {
			float: left;
			padding-right: 32px;
			text-align: center;
			cursor: pointer;
			&.active {
				color: $primary;
			}
			&:hover {
				color: $primary;
			}
		}
	}

	.full-input-w {
		width: 100%;
	}

	.nav-tabs-label {
		display: inline-block;
		padding: 0 16px;
	}

	.page-search-wrapper {
		padding: 0;
	}

	.page-item-wrapper {
		.page-item {
			float: left;
			margin-right: 20px;
			margin-bottom: 40px;
		}
	}
</style>
<style lang="scss">
	.my-page-list {
		.page-search-wrapper {
			.el-tabs__header {
				margin: 0;
			}
		}
	}
</style>
